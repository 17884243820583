<template>
  <div class="filter">
    <div class="modal-overlay" v-if="showModal">
      <div class="modal-container">
        <purchaseModal @showModal="changeShowModal" />
      </div>
    </div>
    <div class="filter-block container flex">
      <ul class="filter__items flex">
        <li class="filter__item search">
          <input
            v-model="inputSearch"
            class="filter__search search-input"
            type="text"
            placeholder="Поиск ..."
            @input="filterDatabase()"
          />
          <img class="search-icon" src="@/assets/png/search-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <button class="filter__item-btn" @click="dateShow = !dateShow">
            Дата закупки
            <b-icon
              class="filter__item-btn-icon icon-right"
              style="color: #707070; margin-left: 10px"
              icon="calendar4-week"
            />
          </button>
          <div v-show="dateShow" class="sub-header__calendar">
            <vc-date-picker
              v-model="date"
              class="add__calendar"
              color="green"
              :rows="1"
              @input="inputData()"
            />
            <button class="btn btn-primary mt-2" @click="hideModal()">Ok</button>
            <button class="btn btn-primary mt-2" @click="inputDataClean('')">Очистить</button>
          </div>
        </li>
        <li class="filter__item sub-header__search-block" style="min-width: 300px"></li>
        <li class="filter__item sub-header__search-block" style="min-width: 300px"></li>
        <li class="filter__item sub-header__search-block right">
          <button class="header__btn right-item default-btn" @click="changeShowModal()">
            <img src="@/assets/svg/cart.svg" alt="" />
            Провести закуп
          </button>
        </li>
      </ul>
      <slot name="logbook" class=""></slot>
    </div>
    <div v-if="dateShow" class="main-close" @click="dateShow = false" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    purchaseModal: () => ({
      component: import("@/components/add/purchase/purchaseModal"),
    }),
  },
  data() {
    return {
      showModal: false,
      selected: null,
      date: null,
      address: null,
      dateShow: false,
      inputSearch: "",
    };
  },
  methods: {
    inputDataClean() {
      this.$store.commit("dateFilter", null);
      this.hideModal();

      // убираем из ссылки
      const searchURL = new URL(window.location);
      searchURL.searchParams.delete("created_at");
      window.history.pushState({}, "", searchURL);
    },
    hideModal() {
      this.dateShow = false;
    },
    changeShowModal() {
      this.showModal = !this.showModal;
    },
    filterDatabase() {
      this.$store.commit("textFilter", this.inputSearch);
      this.$store.commit("dateFilter", null);
      this.$store.commit("typeFilter", this.type);
    },
    inputData() {
      this.date = new Date(this.date).toISOString("ru-RU").split("T")[0];
      this.$store.commit("dateFilter", this.date);

      // переводим данные в ссылку
      const searchURL = new URL(window.location);

      searchURL.searchParams.set("created_at", this.date);

      window.history.pushState({}, "", searchURL);
    },
  },
  computed: {
    ...mapState(["textFilter", "dateFilter", "typeFilter"]),
  },
};
</script>

<style scoped>
.filter {
  padding: 10px 0 10px 0;
  background-color: #fff;
  border-top: 1px solid #f2f2f8;
  position: relative;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}

.add__calendar {
  position: static;
  background: #fff;
}
.filter__item:not(:last-child) {
  margin-right: 30px;
}
.filter__search {
  max-width: 100%;
  color: #acacac;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 45px;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.filter__search-address {
  font-size: 1rem;
}
.filter__item-select {
  /* background: #FFFFFF; */
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 250px;
}
.select-map {
  width: 180px;
}
.main__select-icon {
  top: 50%;
}
.filter__item-btn {
  height: 100%;
  width: 250px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.filter__item-btn-icon {
  right: 3px;
}
.sub-header__search-block {
  position: relative;
  /* margin: 0px 0 0 38px; */
  height: 40px;
}
.modal-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  transform: translateY(0%);
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
  max-width: 100%;
  min-width: 300px;
  z-index: 12;
}
</style>
